export default class TestCompletionBar {
	constructor() {
		// this.questionsAll
		this.buttons = document.getElementById("buttons");
		this.questionAll = window.questions;
		this.fillBarIncrement = 100 / this.questionAll.length;
		this.completion = document.querySelector(".completion-bar__filler");
		this.answersAll = document.querySelectorAll(".answers");
		this.done = document.getElementById("done");
		this.inputs = document.querySelectorAll("input");
		// this.init();
		this.events();
	}
	events() {
		this.inputs.forEach((input) =>
			input.addEventListener("click", () => {
				this.fillCompletionBar();
				this.checkDone();
			})
		);
	}

	// init() {
	// 	// this.draggie = new Draggabilly(this.buttons, {});
	// }

	fillCompletionBar() {
		let fill = 0;
		const every = Array.from(this.answersAll); // this is all answer collections in all questions
		every.every((answers) => {
			const answersArray = Array.from(answers.children);

			const checked = answersArray.some((answer) => {
				const radio = answer.querySelector("input");

				return radio.checked;
			});

			if (checked) fill = fill + this.fillBarIncrement;
			if (checked) return true;
		});

		if (every) this.done.classList.remove("hidden");

		this.completion.style.width = `${fill}%`;
	}
}
