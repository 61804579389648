import HomePage from "./modules/HomePage.js";
import SubSectionedTest from "./modules/TestTemplates/SubSectionedTest.js";
import TestCompletionBar from "./modules/TestTemplates/TestCompletionBar.js";
import QuestionedTest from "./modules/TestTemplates/QuestionedTest.js";
import SubSectionedAnimated from "./modules/TestTemplates/SubSectionedAnimated.js";

if (document.getElementById("home")) {
	new HomePage();
}

if (document.getElementById("completion")) {
	new OrganizeTest();
}

if (document.getElementById("sub-sectioned-test")) {
	// new SubSectionedTest();
	new SubSectionedAnimated();
}
if (document.getElementById("questioned-test")) {
	// new SubSectionedTest();
	new QuestionedTest();
}

if (document.getElementById("completion-bar")) new TestCompletionBar();
