export default class HomePage {
	constructor() {
		this.sections = Array.from(document.querySelectorAll('section'))
		this.events()
		this.initiatScrollEvent()
	}

	events(){
		window.addEventListener('scroll', ()=> this.reveal())
	}

	initiatScrollEvent(){
		this.testLibContainer = document.querySelector('.test-card__container')
		console.log(this.testLibContainer);
		this.sections.push(this.testLibContainer)
		this.sections.forEach(sec=> sec.classList.add('hidden-for-scroll'))
	}

	reveal(){
		this.sections.forEach(sec => {
			const windowHeight = window.innerHeight;
			const revealtop = sec.getBoundingClientRect().top
			const revealPoint = 150

			if (revealtop < windowHeight - revealPoint)
				sec.classList.remove("hidden-for-scroll")
		})
	}




	// methods
	// This will add a class to an element when scrolled


}
