export default class SubSectionedTest {
  constructor() {
    this.sections = document.getElementById("sections");
    this.sectionAll = document.querySelectorAll(".section");
    this.subSectionAll = document.querySelectorAll(".sub-section");
    this.questionAll = document.querySelectorAll(".sub-section__question");
    this.subSections = document.querySelectorAll(".sub-sections");
    this.next = document.getElementById("next");
    this.back = document.getElementById("back");
    this.done = document.getElementById("done");
    this.allInputs = document.querySelectorAll("input");
    this.completion = document.querySelector(".completion-bar__filler");

    this.init();
    this.events();
  }

  //events

  events() {
    // Steps are simple, identify what is shown and show the next thing you want to show
    this.next.addEventListener("click", (e) => this.nextHandler(e));
    this.back.addEventListener("click", (e) => this.previousHandler(e));
    this.allInputs.forEach((input) =>
      input.addEventListener("click", () => {
        this.fillCompletionBar();
      })
    );
  }

  ///////////////////// Next and previous button methods//////////////////////
  init() {
    this.subSectionAll.forEach((subSection) => {
      subSection.classList.add("hidden");
      subSection.style.borderBottom = "none";
    });

    this.sectionAll.forEach((subSection) => subSection.classList.add("hidden"));
    this.subSections.forEach((el) => el.classList.add("hidden"));

    this.sectionAll[0].classList.remove("hidden");
    this.next.classList.remove("hidden");
    this.back.classList.remove("hidden");

    this.fillBarIncrement = 100 / this.questionAll.length;
    this.answersAll = document.querySelectorAll(".answers");
    this.fillCompletionBar();
  }

  resetState() {
    this.currentSection = this.identifyShownChild(this.sections);
    this.currentInnerSection = this.identifyShownChild(this.currentSection);
    this.isLastSubSection = false;
    this.isLastSection = false;
    this.isLastInnerSection = false;
  }

  nextHandler(e) {
    // if we are in section intro

    this.resetState();

    if (this.currentInnerSection.classList.contains("section__introduction"))
      this.isLastInnerSection = this.nextInnerSection();
    // value going in of currentInnerSection is expected
    // to be updated from previous condition
    if (this.currentInnerSection.classList.contains("sub-sections"))
      this.isLastSubSection = this.nextSubSection();

    if (this.isLastSubSection) this.isLastSection = this.nextSection();

    if (this.isLastSubSection && this.isLastSection) return;

    // if (this.isLastSubSection)
    // 	this.hide(this.currentInnerSection.lastElementChild)
  }
  previousHandler(e) {
    this.resetState();
    if (this.currentInnerSection.classList.contains("sub-sections"))
      this.isLastSubSection = this.previousSubSection();

    if (this.currentInnerSection.classList.contains("section__introduction"))
      this.isLastSection = this.previousSection();

    if (this.isLastSection) return;

    if (this.isLastSubSection) {
      this.hide(this.currentInnerSection.children[0]);
      this.previousInnerSection();
    }
    // value going in of currentInnerSection is expected
    // to be updated from previous condition
  }

  nextInnerSection() {
    const next = this.showNext(this.currentInnerSection);

    //Is it the last section?
    if (!next) return true;
    //Else go next inner section

    this.currentInnerSection = next;
    return false;
  }
  previousInnerSection() {
    const next = this.showPrev(this.currentInnerSection);

    //Is it the last section?
    if (!next) return true;
    //Else go next inner section

    this.currentInnerSection = next;
    return false;
  }
  nextSubSection() {
    let subSection = this.identifyShownChild(this.currentInnerSection);
    if (!subSection) {
      subSection = this.currentInnerSection.children[0];
      this.show(subSection);
      return false;
    }

    // Normal behavious
    const next = this.showNext(subSection);
    if (next) return false;
    else return true;
  }
  previousSubSection() {
    let subSection = this.identifyShownChild(this.currentInnerSection);
    if (!subSection) {
      subSection = this.currentInnerSection.lastElementChild;
      this.show(subSection);
      return false;
    }

    // Normal behavious
    const next = this.showPrev(subSection);
    if (next) return false;
    else return true;
  }
  nextSection() {
    const current = this.identifyShownChild(this.sections);

    const next = this.showNext(current);

    if (next) return false;
    else return true;
  }
  previousSection() {
    const current = this.identifyShownChild(this.sections);

    const next = this.showPrev(current);

    if (next) return false;
    else return true;
  }

  /**
   * @param {Object} element The element of which you want to find the child that is shown.
   * @return {Object} Returns the child element that is currently shown on display
   */
  identifyShownChild(element) {
    const innerElements = Array.from(element.children); // expect only elements and no text
    const shown = innerElements.find((el) => !el.classList.contains("hidden"));
    return shown;
    // this.currentlyShownDiv
  }

  /**
   * @param {Object} element Show this element
   */
  show(element) {
    element.classList.remove("hidden");
  }

  /**
   * @param {Object} element Show next element and return it's value
   */
  showNext(element) {
    if (!element.nextElementSibling) return undefined;
    this.hide(element);
    this.show(element.nextElementSibling);
    return element.nextElementSibling;
  }

  /**
   * @param {Object} element Show previous element and return it's value
   */
  showPrev(element) {
    if (!element.previousElementSibling) return undefined;
    this.hide(element);
    this.show(element.previousElementSibling);
    return element.previousElementSibling;
  }

  /**
   * @param {Object} element Hide this element
   */
  hide(element) {
    if (!element) return;
    element.classList.add("hidden");
  }

  //////Specific methods//////////
  /**
   * @param {Object} subSections Hide this element
   */
  hideLastSubSection(subSections) {}

  /////////////////////////Completion bar handler///////////////////////

  fillCompletionBar() {
    let fill = 0;
    this.answersAll; // this is all answer collections in all questions
    this.answersAll.forEach((answers) => {
      const answersArray = Array.from(answers.children);
      console.log(answersArray);
      const checked = answersArray.some((answer) => {
        const radio = answer.querySelector("input");

        return radio.checked;
      });
      if (checked) fill = fill + this.fillBarIncrement;
      console.log(checked);
    });

    this.completion.style.width = `${fill}%`;

    console.log("Fill triggered", this.fillBarIncrement);
  }
}
