export default class SubSectionedAnimated {
  constructor() {
    this.sections = document.getElementById("sections");
    this.sectionAll = document.querySelectorAll(".section");
    this.subSectionAll = document.querySelectorAll(".sub-section");
    this.questionAll = document.querySelectorAll(".sub-section__question");
    window.questions = this.questionAll;
    this.subSections = document.querySelectorAll(".sub-sections");

    this.begin = document.getElementById("begin");
    this.next = document.getElementById("next");
    this.back = document.getElementById("back");
    this.done = document.getElementById("done");
    this.buttons = document.getElementById("buttons");
    this.allInputs = document.querySelectorAll("input");
    this.iterable = [];
    this.init();
    this.events();
  }
  events() {
    this.next.addEventListener("click", () => this.nextHandler());

    this.back.addEventListener("click", () => this.backHandler());

    this.begin.addEventListener("click", () => this.beginTest());

    this.allInputs.forEach((input) =>
      input.addEventListener("click", () => this.nextHandler())
    );
  }

  beginTest() {
    this.begin.classList.add("hidden");
    this.buttons.classList.add("engage");
    this.buttons.parentElement.classList.add("engage");
    this.nextHandler();
    setTimeout(() => {
      this.buttons.parentElement.removeAttribute("style");

      this.buttons.parentElement.classList.remove("init");
    }, 700);
  }

  init() {
    //Create the array we want to iterate

    this.setUpIterable();
    this.sections.classList.remove("no-js");
    this.setUpScrollable();
    this.initialiseButtons();
    window.iterable = this.iterable;

    console.log("////////////////Initialised///////////");
  }

  nextHandler() {
    const index = this.findCurrentMain(this.iterable);

    //Sub Element
    const sub = this.iterable[index].sub;
    if (sub) {
      this.scrollDownSub(sub) || this.scrollDownMain(index, sub);
    } else {
      this.scrollDownMain(index);
    }
  }
  backHandler() {
    const index = this.findCurrentMain(this.iterable);

    //Sub Element
    const sub = this.iterable[index].sub;
    if (sub) {
      this.scrollUpSub(sub) || this.scrollUpMain(index, sub);
    } else {
      this.scrollUpMain(index);
    }
  }

  /**
   * @description Find Current shown element in iterable
   */
  findCurrentMain(iterable) {
    return iterable.findIndex(
      (el) =>
        !el.main.classList.contains("scrolled-down") &&
        !el.main.classList.contains("scrolled-up")
    );
  }

  findCurrentSub(sub) {
    return sub.findIndex(
      (el) =>
        !el.classList.contains("scrolled-down") &&
        !el.classList.contains("scrolled-up")
    );
  }

  scrollDownMain(index, sub) {
    if (!this.iterable[index + 1]) return false;
    //Hide button if next main is last
    if (!this.iterable[index + 2]) this.next.classList.add("hidden");
    this.back.classList.remove("hidden");
    if (
      this.iterable[index + 1].main.classList.contains("section__introduction")
    )
      this.centerBtns();
    else this.uncenterBtns();

    this.iterable[index].main.classList.add("scrolled-up");
    // Next main
    if (sub) sub[this.lastIndex(sub)].classList.add("scrolled-up");

    this.iterable[index + 1].main.classList.remove("scrolled-down");
    if (this.iterable[index + 1].sub) {
      this.iterable[index + 1].sub[0].classList.remove("scrolled-down");

      this.adjustBtnHeight(this.iterable[index + 1].sub[0]);
    } else {
      this.adjustBtnHeight(this.iterable[index + 1].main, 50);
    }
    this.adjustOverallSize(this.iterable[index + 1].main);
  }
  scrollDownSub(sub) {
    const index = this.findCurrentSub(sub);
    if (sub[index + 1]) {
      //Perform next operations
      sub[index].classList.add("scrolled-up");
      sub[index + 1].classList.remove("scrolled-down");
      this.adjustBtnHeight(sub[index + 1]);
      this.isButtonsBiggerOverallSize();
      return true;
    } else return false;
  }

  scrollUpSub(sub) {
    const index = this.findCurrentSub(sub);
    if (sub[index - 1]) {
      //Perform next operations
      sub[index].classList.add("scrolled-down");
      sub[index - 1].classList.remove("scrolled-up");
      this.adjustBtnHeight(sub[index - 1]);
      return true;
    } else return false;
  }
  scrollUpMain(index, sub) {
    if (!this.iterable[index - 1]) return false;
    if (!this.iterable[index - 2]) this.back.classList.add("hidden");
    this.next.classList.remove("hidden");
    if (
      this.iterable[index - 1].main.classList.contains("section__introduction")
    )
      this.centerBtns();
    else this.uncenterBtns();

    this.iterable[index].main.classList.add("scrolled-down");
    // Next main
    if (sub) sub[0].classList.add("scrolled-down");

    this.iterable[index - 1].main.classList.remove("scrolled-up");
    if (this.iterable[index - 1].sub) {
      this.iterable[index - 1].sub[
        this.lastIndex(this.iterable[index - 1].sub)
      ].classList.remove("scrolled-up");

      this.adjustBtnHeight(
        this.iterable[index - 1].sub[
          this.lastIndex(this.iterable[index - 1].sub)
        ]
      );
    } else {
      this.adjustBtnHeight(this.iterable[index - 1].main, 50);
    }
    this.adjustOverallSize(this.iterable[index - 1].main);
  }

  setUpScrollable() {
    this.iterable.forEach((el, i) => {
      if (i === 0) {
        el.main.classList.add("scrollable");
        this.sections.style.minHeight = "100vh";
        this.adjustOverallSize(el.main);
      } else {
        el.main.classList.add("scrollable", "scrolled-down", "initialise");
        if (el.sub) {
          el.sub.forEach((sub) => {
            sub.classList.add("scrollable", "scrolled-down", "initialise");
          });
        }
      }
    });

    setTimeout(() => {
      this.iterable.forEach((el) => {
        el.main.classList.remove("initialise");
        if (el.sub) el.sub.forEach((sub) => sub.classList.remove("initialise"));
      });
    }, 600);
  }

  //Button methods
  initialiseButtons() {
    //Initialise Hidden
    this.next.classList.remove("hidden");
    this.back.classList.remove("hidden");
    this.begin.classList.remove("hidden");
    this.adjustBtnPosition(this.iterable[0].main);
    this.done.classList.add("sub", "hidden");
    this.done.style.bottom = `-10rem`;
    this.buttons.classList.add("sub");
    this.buttons.parentElement.classList.add("sub", "init");

    this.begin.classList.add("sub");
    this.next.classList.add("sub", "sub--next");
    this.back.classList.add("sub", "sub--back");

    //Prepare positions
  }

  //Function helpers
  isLastEl(arr, el) {
    const i = arr.findIndex(el);
    if (i === this.lastIndex(arr)) return true;
    else return false;
  }

  lastIndex(arr) {
    return arr.length - 1;
  }

  /**
   * @description Sets up an array of iterable HTML elements to scroll through
   */
  setUpIterable() {
    this.sectionAll.forEach((section, i) => {
      section.childNodes.forEach((el) => {
        if (el.classList.contains("section__introduction")) {
          this.iterable.push({ main: el });
        }
        if (el.classList.contains("sub-sections")) {
          el.childNodes.forEach((subSec) => {
            let main;
            let sub = [];
            const subSection = {};

            subSec.childNodes.forEach((el) => {
              if (el.classList.contains("sub-content")) main = el;
              if (el.classList.contains("sub-section__questions")) {
                el.childNodes.forEach((question) => {
                  if (question.classList.contains("sub-section__question"))
                    sub.push(question);
                });
              }
            });
            if (main) subSection.main = main;
            if (sub.length > 0) subSection.sub = sub;

            if (Object.keys(subSection).length === 0)
              return new Error(
                `No content or questions present in sub-section of section: ${i} `
              );
            else this.iterable.push(subSection);
          });
        }
      });
    });
  }

  /**
   * @description Adjusts the height of the section (this.sections) using an element
   * @param {Object} el - HTML element
   */
  adjustBtnHeight(el, reducer) {
    const height = el.offsetHeight;
    let computedHeight = height + 200;
    if (reducer) computedHeight = computedHeight - reducer;
    this.buttons.style.height = `${computedHeight}px`;
  }
  adjustBtnPosition(el) {
    // const height = el.offsetHeight;
    this.buttons.parentElement.style.position = "fixed";
  }
  isButtonsBiggerOverallSize() {
    const btnsHeight = this.buttons.getBoundingClientRect();
    const overallHeight = this.sections.getBoundingClientRect();
    if (btnsHeight > overallHeight)
      this.sections.style.height = `${2 * overallHeight - btnsHeight}px`;
  }

  incrementOverallSize(increment) {
    const height = this.sections.style.height;
    this.sections.style.height = `${height + increment}px`;
  }

  centerBtns() {
    this.buttons.parentElement.classList.add("centered");
  }
  uncenterBtns() {
    this.buttons.parentElement.classList.remove("centered");
  }

  adjustOverallSize(el) {
    const height = el.offsetHeight;
    this.sections.style.height = `${height + 300}px`;
  }
}
